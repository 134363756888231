import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import styles from './Events.module.css'
import { useParams } from 'react-router-dom'
import { getUserEvents } from '../../store/analytics/analytics.slice'
import { useState } from 'react'
import Text from '../../components/Text'
import IconButton from '../../components/IconButton/IconButton'
import Plus from '../../assets/icons/Plus'
import CodeSubmission from '../../assets/icons/events/CodeSubmission'
import CodeSubmissionFailed from '../../assets/icons/events/CodeSubmissionFailed'
import { useDispatch, useSelector } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import SessionIcon from '../../assets/icons/events/Session'
import Signup from '../../assets/icons/events/Signup'
import Login from '../../assets/icons/events/Login'
import Location from '../../assets/icons/Location'
import Browser from '../../assets/icons/Browser'
import Computer from '../../assets/icons/Computer'
import Minus from '../../assets/icons/Minus'

const toTimeString = (timestamp) => {
    let res = ''
    let minutes = 0
    let hours = 0
    let days = 0
    let seconds = Math.floor(timestamp / (1000 * 1000))
    res = `${seconds}s`
    if (seconds > 60) {
        minutes = Math.floor(seconds / 60)
        seconds %= 60
        res = `${minutes}m${seconds}s`
    }
    if (minutes > 60) {
        hours = Math.floor(minutes / 60)
        minutes %= 60
        res = `${hours}h${minutes}m${seconds}s`
    }
    if (hours > 24) {
        days = Math.floor(hours / 24)
        hours %= 24
        res = `${days}d${hours}h${minutes}m${seconds}s`
    }

    return res;
}

const Event = ({ event_location, event_name, event_timestamp, test_failed, course_id, lesson_id }) => {
    let Icon = null;
    switch (event_name) {
        case 'code_submission':
            Icon = !test_failed ? CodeSubmissionFailed : CodeSubmission
            break;
        case 'signup':
            Icon = Signup
            break;
        case 'login':
            Icon = Login
            break;

        default:
            break;
    }
    return (
        <Layout row alignItems='flex-start' justifyContent='flex-start' gap={16}>
            <div style={{ width: '64px', height: '64px', backgroundColor: 'cyan', borderRadius: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                {Icon ? <Icon size={64} /> : null}
            </div>
            <Layout alignItems='flex-start' gap={4}>
                <Text text={event_name} type='subTitle' />
                <Text text={event_name === 'code_submission' ? `${course_id}/${lesson_id}` : event_location} />
                <Text text={new Date(event_timestamp / 1000).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'medium' })} type='small' />
            </Layout>
        </Layout>
    )
}

const Session = ({ platform, device, events, geo, index, onMouseEnter, selected }) => {
    const [expended, setExpended] = useState(false)
    return (
        <Layout
            onMouseEnter={onMouseEnter}
            alignItems='flex-start'
            gap={8}
            style={{ position: 'relative', border: '1px solid rgba(0, 0, 0, 0.25)', borderRadius: '8px', backgroundColor: selected ? 'rgba(0, 0, 0, 0.03)' : null }}
            padding={[16]}>
            <IconButton Icon={expended ? Minus : Plus} size={'tiny'} type={'white'} className={styles.expendSessionButton} onClick={() => setExpended(o => !o)} />
            {
                expended ?
                    events.map((event, i) =>
                        event.event_name === 'divider' ?
                            <Divider key={i} timestamp={event.event_timestamp} small /> :
                            <Event key={i} {...event} />) :
                    <Layout row alignItems='flex-start' justifyContent='flex-start' gap={16}>
                        <div style={{ width: '64px', height: '64px', backgroundColor: 'cyan', borderRadius: '40px' }}>
                            <SessionIcon />
                        </div>
                        <Layout alignItems='flex-start' gap={4}>
                            <Text text={`Session #${index}`} type='subTitle' />
                            <Text text={`${Math.floor(events.length / 2) + 1} events`} type='small' />
                            <Text text={toTimeString(events[events.length - 1].event_timestamp - events[0].event_timestamp)} type='small' />
                        </Layout>
                    </Layout>
            }
        </Layout>
    )
}


const Divider = ({ timestamp, small }) => {
    let res = ''
    let minutes = 0
    let hours = 0
    let days = 0
    let seconds = Math.floor(timestamp / (1000 * 1000))
    let height = 4;
    res = `${seconds}s`
    if (seconds > 60) {
        minutes = Math.floor(seconds / 60)
        seconds %= 60
        res = `${minutes}m${seconds}s`
        height = 16
    }
    if (minutes > 60) {
        hours = Math.floor(minutes / 60)
        minutes %= 60
        res = `${hours}h${minutes}m${seconds}s`
        height = 40
    }
    if (hours > 24) {
        days = Math.floor(hours / 24)
        hours %= 24
        res = `${days}d${hours}h${minutes}m${seconds}s`
        height = 80
    }

    return (
        <Layout style={{ width: small ? '64px' : '80px' }}>
            <div style={{ height: height + 'px', border: '1px dashed black', marginBottom: '8px' }} />
            <Text text={res} type='small' color='secondary' />
            <div style={{ height: height + 'px', border: '1px dashed black', marginTop: '8px' }} />
        </Layout>
    )
}

const Events = () => {
    const dispatch = useDispatch()
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`)
    const [selectedSession, setSelectedSession] = useState(0)
    const sessions = useSelector(state => state.analytics.events[selectedDate])
    let { uid } = useParams();

    useEffect(() => {
        const d = selectedDate.split('-');
        const year = Number(d[0])
        const month = Number(d[1])
        const day = Number(d[2])

        dispatch(getUserEvents({ uid, year, month, day }))
    }, [uid, selectedDate, dispatch])

    console.log(sessions);

    return (
        <Layout fullWidth alignItems='flex-start' justifyContent='flex-start' style={{ height: 'calc(100% - 64px)' }}>
            <Layout row padding={[16]} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} fullWidth justifyContent='flex-start'>
                <input
                    type='date'
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    max={`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`} />
            </Layout>
            <Layout row style={{ overflow: 'auto', position: 'relative' }} justifyContent='space-between' alignItems='flex-start' fullWidth>
                <Layout alignItems='flex-start' gap={8} padding={[32]}>
                    {
                        sessions === undefined ?
                            <BounceLoader /> :
                            sessions.length === 0 ?
                                <Text text='No events' /> :
                                sessions?.map((session, i) =>
                                    session.event_name === 'divider' ?
                                        <Divider key={i} timestamp={session.event_timestamp} /> :
                                        <Session key={i} {...session} index={Math.floor(i / 2) + 1} onMouseEnter={_ => setSelectedSession(i)} selected={selectedSession === i} />)
                    }
                </Layout>
                {sessions !== undefined && sessions.length > 0 ?
                    <Layout gap={16} alignItems='flex-start' style={{ position: 'sticky', top: '32px', border: '1px solid rgba(0, 0, 0, 0.25)', borderRadius: '8px', minWidth: '320px', backgroundColor: 'rgba(0, 0, 0, 0.03' }} margin={[32]} padding={[16]}>
                        <Layout alignItems='flex-start'>
                            <Text text={`Session #${Math.floor((selectedSession + 1) / 2) + 1}`} type='subTitle' />
                            <Text text={sessions[selectedSession]?.session_id} type='small' />
                        </Layout>
                        <Layout alignItems='flex-start' gap={8}>
                            <Layout row gap={4} >
                                <Location size={16} />
                                <Text text={`${sessions[selectedSession].geo?.city}, ${sessions[selectedSession].geo?.country}`} type='normal' />
                            </Layout>
                            <Layout row gap={4} >
                                <Browser size={16} />
                                <Text text={`${sessions[selectedSession].device?.browser} (${sessions[selectedSession].device?.category})`} type='normal' />
                            </Layout>
                            <Layout row gap={4} alignItems='flex-start'>
                                <Computer size={16} />
                                <Layout alignItems='flex-start'>
                                    <Text text={sessions[selectedSession].device?.mobile_brand_name} type='normal' />
                                    <Text text={sessions[selectedSession].device?.operating_system} type='small' />
                                    <Text text={sessions[selectedSession].device?.operating_system_version} type='small' />
                                </Layout>
                            </Layout>
                        </Layout>
                    </Layout> : null}
            </Layout>
        </Layout>
    )
}

export default Events