import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  toggleLessonFlowAction,
  resetLessonFlowAction,
  setShowEditBillingAction,
  getUserEventsFulfilled,
  getUserEventsLoading,
  getUserEventsRejected,
} from './analytics.actions'
import FunctionsService from '../../services/FunctionsService'
import Status from '../status'

const TO_FILTER_EVENTS = ['challenge_done']

export const getUserEvents = createAsyncThunk('analytics/getUserEvents', async ({ uid, year, month, day }, { getState }) => {
  const eventsUid = getState().analytics.eventsUid
  const events = getState().analytics.events

  if (eventsUid === uid && events[`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`] !== undefined) {
    return events
  } else {
    let d = await FunctionsService.getUserEvents(uid, year, month, day)
    d = d.map(dd => {
        return {
          ...dd,
          events: dd.events.filter(e => !TO_FILTER_EVENTS.includes(e.event_name))
        }
      }
    )
    for (let i = 0; i < d.length; i++) {
      for (let j = 0; j < d[i].events.length - 1; j++) {
        d[i].events.splice(j + 1, 0, {
          event_name: 'divider',
          event_timestamp: d[i].events[j + 1].event_timestamp - d[i].events[j].event_timestamp,
        })
        j += 1
      }
    }
    for (let i = 0; i < d.length - 1; i++) {
      d.splice(i + 1, 0, {
        event_name: 'divider',
        event_timestamp: d[i + 1].events[0].event_timestamp - d[i].events[d[i].events.length - 1].event_timestamp,
      })
      i += 1
    }
    if (uid === eventsUid) {
      const copy = {
        ...events,
        [`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`]: d
      }
      return copy
    } else {
      return {
        [`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`]: d
      }
    }
  }
})


export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    events: {},
    eventsUid: '',
    eventsStatus: Status.None,
    trackingUid: undefined,
    trackingCourseId: undefined,
    trackingLessonId: undefined,
    lessonName: '',
    chapterName: '',
    hintsSeen: {},
    solutionDate: undefined,
    askedAi: false,
    showBillingEdit: false,
    quizProgress: {},
  },
  reducers: {
    toggleLessonFlow: toggleLessonFlowAction,
    resetLessonFlow: resetLessonFlowAction,
    setShowEditBilling: setShowEditBillingAction,
  },
  extraReducers(builder) {
    builder
      .addCase(getUserEvents.pending, getUserEventsLoading)
      .addCase(getUserEvents.fulfilled, getUserEventsFulfilled)
      .addCase(getUserEvents.rejected, getUserEventsRejected)
  },
})

export const {
  toggleLessonFlow,
  resetLessonFlow,
  setShowEditBilling
} = analyticsSlice.actions

export default analyticsSlice.reducer