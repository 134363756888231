import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { MARGIN } from '../../Constants'
import CourseView from './CourseView'
import FunctionsService from '../../services/FunctionsService'

const CoursesProgress = ({ uid, onCourseClick, isChallenges = false, isNewJourney=false}) => {
    const [progress, setProgress] = useState({})
    const [activeCourseId, setActiveCourseId] = useState('')

    useEffect(() => {
        FunctionsService.getCoddyUserProgress(uid, isChallenges)
            .then(d => setProgress(d))
    }, [uid, isChallenges])

    const handleCourseClick = (courseId) => {
        setActiveCourseId(courseId)
        onCourseClick(courseId)
    }
    const handleSectionClick = (journeyId, sectionId) => {
        setActiveCourseId(`${journeyId}-${sectionId}`)
        onCourseClick(`${journeyId}-${sectionId}`)
    }

    return (
        <Layout scroll alignItems='start' justifyContent='start' gap={MARGIN / 2} fullWidth>
            {
                Object.keys(progress).map(courseId => {
                    if (isNewJourney && courseId.includes("-")) {
                        const temp = courseId.split("-")
                        const journeyId = temp[0]
                        const sectionId = temp[1]
                        return <CourseView
                        key={courseId}
                        journeyId={journeyId}
                        courseId={sectionId}
                        uid={uid}
                        isActive={activeCourseId === courseId}
                        progress={progress[courseId] ?? {}}
                        onCourseClick={handleCourseClick}
                        onSectionClick={handleSectionClick}
                        isChallenge={isChallenges} />
                    } else if (!isNewJourney && !courseId.includes("-")) {
                        return <CourseView
                        key={courseId}
                        courseId={courseId}
                        uid={uid}
                        isActive={activeCourseId === courseId}
                        progress={progress[courseId] ?? {}}
                        onCourseClick={handleCourseClick}
                        isChallenge={isChallenges} />
                    } else {
                        return null
                    }
                })
            }
        </Layout>

    )
}

export default CoursesProgress