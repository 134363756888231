import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { fetchCourses } from '../../store/tagging/tagging.slice'
import styles from './Popup.module.css'
import { saveMarketingFunnel } from '../../store/funnelOptions/funnelOptions.slice'
import { deepEqual } from '../../util/helpers'
import Status from '../../store/status'


const Option = ({
    option,
    handleSetFrom,
    handleSetInfo,
    handleSetPromo,
    handleAddFrom,
    handleAddInfo,
    handleAddPromo,
}) => {
    return (
        <Layout fullWidth gap={8} color={'#eee'} padding={[8]}>
            <Layout gap={4} alignItems='flex-start' fullWidth>
            <Layout fullWidth justifyContent='space-between' row>
                <Text text='from:' />
                <Button text={'Add from'} type='secondary' onClick={() => handleAddFrom()} />

            </Layout>
                {
                    option?.from?.map((from, index) => {
                        return (
                            <input
                                key={`${index}`}
                                defaultValue={from}
                                className={styles.input}
                                onChange={e => handleSetFrom(index, e.target.value)} />
                        )
                    })
                }
            </Layout>
            <Layout gap={4} alignItems='flex-start' fullWidth>
            <Layout fullWidth justifyContent='space-between' row>
                <Text text='info:' />
                <Button text={'Add info'} type='secondary' onClick={() => handleAddInfo()} />
            </Layout>
                {
                    option?.info?.map((info, index) => {
                        return (
                            <input
                                key={`${index}`}
                                defaultValue={info}
                                className={styles.input}
                                onChange={e => handleSetInfo(index, e.target.value)} />
                        )
                    })
                }
            </Layout>
            <Layout row gap={16} fullWidth alignItems='flex-start'>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Layout fullWidth justifyContent='space-between' row>
                        <Text text='promo:' />
                        <Button text={'Add promo'} type='secondary' onClick={() => handleAddPromo()} />
                    </Layout>
                    {
                        option?.promo?.map((promo, index) => {
                            return (
                                <input
                                    key={`${index}`}
                                    defaultValue={promo}
                                    className={styles.input}
                                    onChange={e => { handleSetPromo(index, e.target.value); }} />
                            )
                        })
                    }
                </Layout>
            </Layout>
        </Layout>
    )
}

const MarketingFunnelPopup = ({ id }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const savedData = useSelector(state => id ? state.funnelOptions.data?.marketingFunnels[id] : {})
    const idsList = Object.keys(useSelector(state => state.funnelOptions.data?.marketingFunnels ?? {}))
    const saveMarketingFunnelStatus = useSelector(state => state.funnelOptions.saveMarketingFunnelStatus)
    const [data, setData] = useState({})
    const [newId, setNewId] = useState(null)
    useEffect(() => {
        if (id != null) {
            setNewId(id)
            setData(savedData)
        }
    // eslint-disable-next-line
    }, [savedData])
    
    useEffect(() => {
        dispatch(fetchCourses())
    }, [dispatch])
    
    const handleSave = () => {
        dispatch(saveMarketingFunnel({ id: newId, data: data, navigate: navigate }))
    }
    
    
    const handleSetFrom = (index, from) => {
        let temp = data.from ?? []
        temp = [...temp]
        temp[index] = from
        setData(d => {
            return {
                ...d,
                from: temp
            }
        })
    }
    
    const handleSetInfo = (index, info) => {
        let temp = data.info ?? []
        temp = [...temp]
        temp[index] = info
        setData(d => {
            return {
                ...d,
                info: temp
            }
        })
    }
    
    const handleSetPromo = (index, promo) => {
        let temp = data.promo ?? []
        temp = [...temp]
        temp[index] = promo
        setData(d => {
            return {
                ...d,
                promo: temp
            }
        })
    }

    const handleAddFrom = () => {
        let temp = data.from
        if (temp == null) {
            temp = []
        }
        temp = [...temp]
        temp.push("")
        setData(d => {
            return {
                ...d,
                from: temp
            }
        })
    }

    const handleAddInfo = () => {
        let temp = data.info
        if (temp == null) {
            temp = []
        }
        temp = [...temp]
        temp.push("")
        setData(d => {
            return {
                ...d,
                info: temp
            }
        })
    }

    const handleAddPromo = () => {
        let temp = data.promo
        if (temp == null) {
            temp = []
        }
        temp = [...temp]
        temp.push("")
        setData(d => {
            return {
                ...d,
                promo: temp
            }
        })
    }

    return (
        <Layout classes={[styles.backdrop, styles.display]} alignItems='flex-end' onClick={() => { navigate(`/funnel_options`) }}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[32, 32, 0, 32]} justifyContent='flex-start' alignItems='flex-start' gap={32}>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text={id == null ? 'Add new marketing funnel' : 'Marketing Funnel ID:'} />
                    <input
                        value={id}
                        className={styles.input}
                        onChange={e => setNewId(e.target.value)} 
                        disabled={id != null} />
                </Layout>
                <Layout alignItems='flex-start' fullWidth gap={16}>
                    <Layout gap={16} fullWidth alignItems='flex-start'>
                        <Option
                            option={data}
                            handleSetFrom={handleSetFrom}
                            handleSetInfo={handleSetInfo}
                            handleSetPromo={handleSetPromo}
                            handleAddFrom={handleAddFrom}
                            handleAddInfo={handleAddInfo}
                            handleAddPromo={handleAddPromo}
                        />

                    </Layout>
                </Layout>
                <Layout row classes={[styles.bottomContainer]} fullWidth justifyContent='flex-start' padding={[8, 0]} gap={8}>
                    <Button
                        text={'Save'}
                        onClick={handleSave}
                        disabled={saveMarketingFunnelStatus === Status.Loading || newId == null || newId === '' || ((id == null || id === '') && idsList.includes(newId)) || deepEqual(data, savedData) ||
                                    data.info?.filter((d) => d == null || d === '').length > 0 ||
                                    data.from?.filter((d) => d == null || d === '').length > 0 ||
                                    data.promo?.filter((d) => d == null || d === '').length > 0
                                } 
                        />
                    {
                        saveMarketingFunnelStatus === Status.Loading ? <Text text={"Loading..."}/> : null
                   } 
                </Layout>
            </Layout>
        </Layout>
    )
}

export default MarketingFunnelPopup