import { toast } from "react-toastify"
import Status from "../status"

export const toggleLessonFlowAction = (state, action) => {
    state.trackingUid = action.payload?.uid
    state.trackingJourneyId = action.payload?.journeyId
    state.trackingCourseId = action.payload?.courseId
    state.trackingLessonId = action.payload?.lessonId
    state.lessonName = action.payload?.lessonName
    state.chapterName = action.payload?.chapterName
    state.hintsSeen = action.payload?.hintsSeen ?? {}
    state.solutionDate = action.payload?.solutionDate
    state.askedAi = action.payload?.askedAi
    state.quizProgress = action.payload?.quizProgress ?? {}
}

export const resetLessonFlowAction = (state, action) => {
    state.trackingUid = undefined
    state.trackingJourneyId = undefined
    state.trackingCourseId = undefined
    state.trackingLessonId = undefined
    state.lessonName = ''
    state.chapterName = ''
    state.hintsSeen = {}
    state.solutionDate = undefined
    state.quizProgress = {}
}

export const setShowEditBillingAction = (state, action) => {
    state.showBillingEdit = action.payload
}

export const getUserEventsFulfilled = (state, action) => {
    state.eventsUid = action.meta.arg.uid
    state.events = action.payload
    state.eventsStatus = Status.Success
}

export const getUserEventsLoading = (state, action) => {
    state.eventsStatus = Status.Loading
}

export const getUserEventsRejected = (state, action) => {
    state.eventsStatus = Status.Fail
    toast.error(action.error.message)
}
