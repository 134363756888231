import { getApp } from "@firebase/app"
import {
    doc,
    getDoc,
    getFirestore,
    collection,
    query,
    getDocs,
    orderBy,
    limit,
    where,
    updateDoc,
    setDoc,
    deleteDoc,
    getCountFromServer,
} from "@firebase/firestore";
import { PERMISSIONS_LEVELS } from "../Constants";
import { addDoc } from "firebase/firestore";

export default class DatabaseService {
    static getUser = async (uid, email) => {
        const db = getFirestore(getApp())
        const ref = doc(db, `users/${uid}`)
        const result = await getDoc(ref)
        if (!result.exists()) {
            const data = {
                email: email,
                uid: uid
            }
            await setDoc(ref, data)
            return data
        } else
            return result.data()


    }

    static getCourse = async (courseId) => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, 'courses', courseId)
        const result = await getDoc(ref)
        const data = result.data()
        data.id = result.id
        return data
    }

    static getSection = async (journeyId, sectionId) => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, 'journeys', journeyId, 'sections', sectionId)
        const result = await getDoc(ref)
        const data = result.data()
        data.id = result.id
        return data
    }

    static getChallenge = async (challengeId) => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, 'challenges', challengeId)
        const result = await getDoc(ref)
        const data = result.data()
        data.id = result.id
        return data
    }

    static getLesson = async (courseId, lessonId) => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, 'courses', courseId, 'lessons', lessonId)
        const result = await getDoc(ref)
        return result.data()
    }

    static getJourneyLesson = async (journeyId, sectionId, lessonId) => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, 'journeys', journeyId, 'sections',sectionId, 'lessons', lessonId)
        const result = await getDoc(ref)
        return result.data()
    }

    static updateUser = async (uid, updatedData) => {
        const db = getFirestore(getApp())
        const ref = doc(db, `users/${uid}`)
        return await updateDoc(ref, updatedData)
    }

    static getKPIs = async (id) => {
        const db = getFirestore(getApp())
        const ref = doc(db, 'kpis', id)
        const result = await getDoc(ref)
        return result.data()
    }

    static getPermissionsData = async ({ type, uids = [] }) => {
        const accesLevel = PERMISSIONS_LEVELS(type)
        const db = getFirestore(getApp('coddy'))
        const ref = collection(db, 'users')
        let queries = [where('accessLevel', '==', accesLevel)]
        if (uids.length > 0) {
            queries.push(where('uid', 'in', uids))
        }

        const q = query(ref, ...queries)
        const snapshot = await getDocs(q)
        const res = []
        snapshot?.forEach((doc) => {
            const data = doc.data()
            res.push({
                uid: data.uid,
                email: data.email,
                displayName: data.displayName
            })
        })
        return res;
    }

    static getSupportTickets = async (lastTime = undefined, showClose = false) => {
        const db = getFirestore(getApp('coddy'))
        const ref = collection(db, 'support')
        let q;
        if (showClose) {
            q = query(
                ref,
                orderBy('lastUpdated', 'desc'),
                limit(25))
            if (lastTime) {
                q = query(
                    ref,
                    orderBy('lastUpdated', 'desc'),
                    where('lastUpdated', '<', new Date(lastTime)),
                    limit(25))
            }
        } else {
            q = query(
                ref,
                orderBy('lastUpdated', 'desc'),
                where('status', '==', 'open'),
                limit(25))
            if (lastTime) {
                q = query(
                    ref,
                    orderBy('lastUpdated', 'desc'),
                    where('status', '==', 'open'),
                    where('lastUpdated', '<', new Date(lastTime)),
                    limit(25))
            }
        }
        const snapshot = await getDocs(q)
        const res = []
        snapshot.forEach((doc) => {
            const data = doc.data()
            data.lastUpdated = data?.lastUpdated?.toDate()?.getTime() ?? Date.now()
            data.id = doc.id
            data.messages = data.messages.map(m => {
                m.time = m.time.toDate().getTime()
                return m
            })
            res.push(data)
        })

        return res
    }

    static getSupportTicket = async (tid) => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, 'support', tid)
        const result = await getDoc(ref)
        const data = result.data()
        data.lastUpdated = data?.lastUpdated?.toDate()?.getTime() ?? Date.now()
        data.id = result.id
        data.messages = data.messages.map(m => {
            m.time = m.time.toDate().getTime()
            return m
        })
        return data
    }

    static getPayments = async () => {
        const db = getFirestore(getApp('cfc'))
        const db2 = getFirestore(getApp('coddy'))
        const ref = collection(db, 'payments')
        const snapshot = await getDocs(ref)
        const res = []
        for (let i = 0; i < snapshot.docs.length; i++) {
            const data = {
                total: snapshot.docs[i].data().total,
                lastUpdated: snapshot.docs[i].data().lastUpdated.toDate().getTime(),
            }
            data.id = snapshot.docs[i].id
            const dd = await getDoc(doc(db2, 'users', data.id))
            data.creatorInfo = {
                email: dd.data().email,
                displayName: dd.data().displayName,
                creatorVisibility: dd.data().creatorVisibility ?? 'normal',
            }
            res.push(data)
        }
        res.sort((a, b) => b.total - a.total)
        return res;
    }

    static getTags = async () => {
        const db = getFirestore(getApp())
        const ref = doc(db, 'info/tags')
        const result = await getDoc(ref)
        return result.data().data
    }

    static getTag = async (id) => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, `tags/${id}`)
        const result = await getDoc(ref)
        return result.data()
    }

    static getTerms = async () => {
        const db = getFirestore(getApp())
        const ref = doc(db, 'info/terms')
        const result = await getDoc(ref)
        return result.data().data
    }

    static getTerm = async (id) => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, `terms/${id}`)
        const result = await getDoc(ref)
        return result.data()
    }

    static getCourses = async () => {
        const db = getFirestore(getApp())
        const ref = doc(db, 'info/courses')
        const result = await getDoc(ref)
        return result.data().data
    }

    static getContactForms = async (lastTime = undefined) => {
        const db = getFirestore(getApp('coddy'))
        const ref = collection(db, 'contact')
        let q = query(
            ref,
            orderBy('time', 'desc'),
            limit(25))
        if (lastTime) {
            q = query(
                ref,
                orderBy('time', 'desc'),
                where('time', '<', new Date(lastTime)),
                limit(25))
        }
        const snapshot = await getDocs(q)
        const res = []
        snapshot.forEach((doc) => {
            const data = doc.data()
            data.time = data.time?.toDate()?.getTime() ?? Date.now()
            data.id = doc.id
            res.push(data)
        })

        return res
    }

    static getBlogs = async (lastTime = undefined) => {
        const db = getFirestore(getApp('coddy'))
        const ref = collection(db, 'blog')
        let q = query(
            ref,
            orderBy('date', 'desc'),
            limit(25))
        if (lastTime) {
            q = query(
                ref,
                orderBy('date', 'desc'),
                where('date', '<', new Date(lastTime)),
                limit(25))
        }
        const snapshot = await getDocs(q)
        const res = []
        snapshot.forEach((doc) => {
            const data = doc.data()
            data.date = data.date?.toDate()?.getTime() ?? Date.now()
            data.id = doc.id
            res.push(data)
        })

        return res
    }

    static getCarousels = async () => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, 'showcase/coursesIds')
        const result = await getDoc(ref)
        const data = result.data()
        return Object.keys(data).map(k => {
            return {
                id: k,
                courses: data[k]
            }
        })
    }

    static saveCarousels = async (data) => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, 'showcase/coursesIds')
        return setDoc(ref, data)
    }

    static getJourneys = async () => {
        const db = getFirestore(getApp('coddy'))
        const ref = collection(db, 'roadmaps')
        const snapshot = await getDocs(ref)
        const res = []
        snapshot.forEach((doc) => {
            const data = doc.data()
            data.id = doc.id
            res.push(data)
        })
        return res
    }

    static getFunnelOptions = async () => {
        const db = getFirestore(getApp('coddy'))
        const ref = collection(db, 'funnels')
        const snapshot = await getDocs(ref)
        const res = []
        snapshot.forEach((doc) => {
            const data = doc.data()
            data.id = doc.id
            res.push(data)
        })
        return res
    }

    static getMarketingFunnels = async () => {
        const db = getFirestore(getApp())
        const ref = doc(db, 'info/funnels')
        const result = await getDoc(ref)
        return result.data()
    }

    static getRoyalties = async () => {
        const db = getFirestore(getApp())
        const ref = collection(db, 'royalties')
        let q = query(
            ref,
            where('status', '==', 'open'),
            orderBy('endDate', 'desc'))
        const snapshot = await getDocs(q)
        if (snapshot.empty) return []
        return snapshot.docs.map(d => {
            const dd = d.data()
            dd.id = d.id;
            return dd;
        })
    }

    static getRoyaltiesHistory = async () => {
        const db = getFirestore(getApp())
        const ref = collection(db, 'royalties')
        let q = query(
            ref,
            where('status', '==', 'close'))
        const snapshot = await getDocs(q)
        if (snapshot.empty) return []
        return snapshot.docs.map(d => {
            const dd = d.data()
            dd.id = d.id;
            return dd;
        }).sort((a, b) => b.endDate - a.endDate)
    }

    static getMarketingFunnelsInfo = async () => {
        const db = getFirestore(getApp())
        const ref = doc(db, 'info/funnels')
        const result = await getDoc(ref)
        const data = result.data()
        return data;
    }

    static saveMarketingFunnelsInfo = async (data) => {
        const db = getFirestore(getApp())
        const ref = doc(db, 'info/funnels')
        return setDoc(ref, data)
    }

    static getMarketingPerformance = async (publishTime) => {
        const d = new Date()
        const currMonth = d.getMonth() + 1
        const currYear = d.getFullYear()
        const db = getFirestore(getApp())
        const res = []
        const promises = []
        for (let i = currMonth - 19; i < currMonth + 4; i++) {
            const yearKey = i <= 0 ?
                (currYear - (i <= -12 ? 2 : 1)).toString().slice(2) :
                i > 12 ?
                    (currYear + 1).toString().slice(2) :
                    currYear.toString().slice(2)
            const monthKey = i <= 0 ?
                (12 + i <= 0 ? 24 + i : 12 + i).toString() :
                i > 12 ?
                    (i - 12).toString() :
                    i.toString()
            const key = `${yearKey}M${monthKey}`
            const p = getDoc(doc(db, `marketing_performance/${key}`))
                .then((d) => {
                    if (d.exists()) {
                        const data = d.data()
                        data.key = key
                        data.deliverablesCount = data.deliverables?.length ?? 0
                        data.revenueInfo = data?.revenue ?? {}
                        data.revenue = data?.revenue?.total?.amount ? data?.revenue?.total?.amount / 100 : 0
                        if (data.price === undefined) data.price = 0
                        if (data.targetPrice === undefined) data.targetPrice = 0
                        res.push(data)
                    } else {
                        res.push({
                            key,
                            price: 0,
                            targetPrice: 0,
                            revenue: 0,
                            deliverables: [],
                            deliverablesCount: 0,
                            revenueInfo: {}
                        })
                    }
                });
            promises.push(p)
        }
        return await Promise.all(promises).then(() => res);
    }

    static getLastMarketingDeliverables = async (publishTime, fid) => {
        const db = getFirestore(getApp())
        let q;
        if (fid) {
            if (publishTime) {
                q = query(collection(db, 'marketing_deliverables'), where('fid', '==', fid), where('publishTime', "<", publishTime), orderBy('publishTime', 'desc'), limit(20))
            } else {
                q = query(collection(db, 'marketing_deliverables'), where('fid', '==', fid), orderBy('publishTime', 'desc'), limit(20))
            }
        } else {
            if (publishTime) {
                q = query(collection(db, 'marketing_deliverables'), where('publishTime', "<", publishTime), orderBy('publishTime', 'desc'), limit(20))
            } else {
                q = query(collection(db, 'marketing_deliverables'), orderBy('publishTime', 'desc'), limit(20))
            }
        }
        const snapshot = await getDocs(q)
        const res = []
        snapshot.forEach((doc) => {
            const data = doc.data()
            res.push(data)
        })
        return res;
    }

    static getLastMarketingFunnels = async (lastUpdated) => {
        const db = getFirestore(getApp())
        let q;
        if (lastUpdated) {
            q = query(collection(db, 'marketing_funnels'), where('lastUpdated', "<", lastUpdated), orderBy('lastUpdated', 'desc'), limit(20))
        } else {
            q = query(collection(db, 'marketing_funnels'), orderBy('lastUpdated', 'desc'), limit(20))
        }
        const snapshot = await getDocs(q)
        const res = []
        snapshot.forEach((doc) => {
            const data = doc.data()
            data.fid = doc.id
            res.push(data)
        })
        return res;
    }

    static getLastMarketingTickets = async (lastUpdated) => {
        const db = getFirestore(getApp())
        let q;
        if (lastUpdated) {
            q = query(collection(db, 'marketing_tickets'), where('lastUpdated', "<", lastUpdated), orderBy('lastUpdated', 'desc'), limit(20))
        } else {
            q = query(collection(db, 'marketing_tickets'), orderBy('lastUpdated', 'desc'), limit(20))
        }
        const snapshot = await getDocs(q)
        const res = []
        snapshot.forEach((doc) => {
            const data = doc.data()
            data.tid = doc.id
            res.push(data)
        })
        return res;
    }

    static getMarketingTicket = async (tid) => {
        const db = getFirestore(getApp())
        const ref = doc(db, 'marketing_tickets/' + tid)
        const result = await getDoc(ref)
        const data = result.data()
        data.tid = result.tid
        return data;
    }

    static getMarketingFunnel = async (fid) => {
        const db = getFirestore(getApp())
        const ref = doc(db, 'marketing_funnels/' + fid)
        const result = await getDoc(ref)
        if (!result.exists()) return null;
        const data = result.data()
        data.fid = result.fid
        return data;
    }

    static saveMarketingTicket = async (tid, data) => {
        const db = getFirestore(getApp())
        if (tid) {
            return setDoc(doc(db, 'marketing_tickets/' + tid), data, { merge: true })
                .then((_) => tid);
        } else {
            return addDoc(collection(db, 'marketing_tickets'), data)
                .then((d) => d.id);
        }
    }

    static deleteMarketingTicket = (tid) => {
        const db = getFirestore(getApp())
        return deleteDoc(doc(db, 'marketing_tickets/' + tid));
    }

    static saveMarketingFunnel = (fid, data) => {
        const db = getFirestore(getApp())
        return setDoc(doc(db, 'marketing_funnels/' + fid), data, { merge: true });
    }

    static saveMarketingPerformance = (pid, targetPrice) => {
        const db = getFirestore(getApp())
        return setDoc(doc(db, 'marketing_performance/' + pid), { targetPrice }, { merge: true });
    }

    static getDeliverableId = () => {
        const db = getFirestore(getApp())
        return doc(collection(db, 'marketing_tickets')).id;
    }

    static getDeliverablesCount = async (fid) => {
        const db = getFirestore(getApp())
        const q = query(collection(db, "marketing_deliverables"), where("fid", "==", fid));
        const snapshot = await getCountFromServer(q);
        return snapshot.data().count;
    }

    static getTicketsCount = async (fid) => {
        const db = getFirestore(getApp())
        const q = query(collection(db, "marketing_tickets"), where("fid", "==", fid));
        const snapshot = await getCountFromServer(q);
        return snapshot.data().count;
    }
}